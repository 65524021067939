.nadialistyourland{
    background-color: #056774; /* Green */
    border: none;
    color: white;
    padding: 7px 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 3px;
}

.justufytext{
    text-align: justify;
    text-justify: inter-word;
}