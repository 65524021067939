.bishakhagroup{
    background-color: aliceblue;
}

.btn-grad31 {
    background-image: linear-gradient(to right, #574016 0%, #cab28a  51%, #0f4664  100%);
    margin: 0px;
    padding: 5px 14px;
    text-align: center;
    /* text-transform: uppercase; */
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 2px;
    display: block;
  }
  
  .btn-grad31:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .colortextnav a{
    color: #082e43;
  }

  .colortextnav h3{
    color: #082e43;
  }

  .colortextnav h5{
    color: #082e43;
  }